import { CheckBoxOutlineBlankOutlined, ErrorOutline, CheckBoxOutlined, ControlPointDuplicate, Edit, Check, DoDisturb } from "@mui/icons-material";
import { Box, Button, Tooltip } from "@mui/material";
import { Compare } from "src/utils/compare";
import { shipmentServices } from "./services";
import { ShipmentUtils } from "src/utils";
import { getGridDateOperators } from '@mui/x-data-grid';
import { customNumericOperators } from "./customFilterOperators";

export const spotmarketShipmentColumns = (
    session, 
    tenant, 
    invalidShipments = false,
    onApproveItem,
    onDenyItem
) => {
    const poNumberLabel = ShipmentUtils.getPONumberLabel(session?.tenant?._id ?? tenant?._id);

    const cols = [
        {
            field: "primaryReference",
            headerName: "Primary Reference",
            minWidth: 120,
            valueGetter: (params) => {
                /// Merge original primary references for combined shipments for users be able to search for.
                const shipment = params.row;
                const result = `${shipment.primaryReference}, ${shipment.combinedFrom?.map((combined) => combined.primaryReference).join(', ') ?? ''}`;
                return result;
            },
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.primaryReference}
                    </div>
                )
            },
        },
        {
            field: "metadata.spotmarket.postedRate",
            headerName: "Posted Rate",
            minWidth: 120,
            renderCell: (params) => {
              const postedRate = params.row.metadata?.spotmarket?.postedRate;
              const formattedRate = postedRate ? postedRate.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '';
              return formattedRate
            },
            valueGetter: (params) => {
              return params.row.metadata?.spotmarket?.postedRate;
            },
        },
        {
            field: "metadata.spotmarket.truckRateRange",
            headerName: "Truck Rate Range",
            minWidth: 160,
            renderCell: (params) => {
                const truckRateRange = params.row.metadata?.spotmarket?.truckRateRange;
                const minRate = truckRateRange?.min?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) || '-';
                const maxRate = truckRateRange?.max?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) || '-';
        
                return `${minRate} → ${maxRate}`;
            },
            valueGetter: (params) => {
                const truckRateRange = params.row.metadata?.spotmarket?.truckRateRange;
                return truckRateRange ? `${truckRateRange.min || '-'} → ${truckRateRange.max || '-'}` : '';
            },
        },        
        {
            field: "mode",
            headerName: "Mode",
            minWidth: 80,
            align: 'center',
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                        <div className={`invalidPoNumber ${params.row.mode?.toUpperCase()}`}>
                            {params.row.mode?.toUpperCase()}
                        </div>
                    );
                } else {
                    return (
                        <div>
                            {params.row.mode?.toUpperCase()}
                        </div>
                    )
                }
            },
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 120,
            align: 'center',
            renderCell: (params) => {
                const capitalize = (str) => {
                    if (typeof str === 'string') {
                        return str.charAt(0).toUpperCase() + str.slice(1);
                    }
                };
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                        <div className={`invalidPoNumber ${params.row.metadata?.invalidPoNumber}`}>
                            {capitalize(params.row.status)}
                        </div>
                    );
                } else {
                    return (
                        <div className={`cellWithStatus ${params.row.status}`}>
                            {params.row.status}
                        </div>
                    );
                }
            },
        },
        {
            field: "customer",
            headerName: "Customer",
            minWidth: 150,
            align: 'center',
            renderCell: (params) => {
                return (
                    <div>{params.row.customer}</div>
                )
            },
            valueGetter: (params) => params.row.customer,
        },
        {
            field: "created.at",
            headerName: "Created At",
            minWidth: 150,
            align: 'center',
            filterOperators: [ ...getGridDateOperators() ],
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                        <div className={`invalidPoNumber ${fDateString(params.row.created?.at)}`}>
                            {fDateString(params.row.created?.at)}
                        </div>
                    );
                } else {
                    return (
                        <div>{fDateString(params.row.created?.at)}</div>
                    )
                }
            },
            valueGetter: (params) => params.row.created?.at,
            sortComparator: Compare.dayInMoth,
        },
        {
            field: "created.by",
            headerName: "Created By",
            minWidth: 130,
            align: 'center',
            renderCell: (params) => {
                const by = params.row.created?.by;
                if (params.row.metadata?.invalidPoNumber) {
                    if (!by) return <>--</>
                    return (
                        <div className={`invalidPoNumber ${by?.firstname} ${by?.lastname}`}>
                            <>{`${by?.firstname} ${by?.lastname}`}</>
                        </div>
                    );
                } else {
                    if (!by) return <>--</>
                    return <>{`${by?.firstname} ${by?.lastname}`}</>
                }
            },
            valueGetter: (params) => {
                const by = params.row.created?.by;
                return `${by?.firstname} ${by?.lastname}`;
            },
        },
        {
            field: "integrated",
            headerName: "Confirmed",
            minWidth: 80,
            align: 'center',
            renderCell: (params) => params.row.integrated
                ? <CheckBoxOutlined sx={{ opacity: 0.8 }} /> : <CheckBoxOutlineBlankOutlined sx={{ opacity: 0.8 }} />,
        },
        {
            field: "quantity",
            headerName: "Pallets",
            minWidth: 100,
            filterOperators: [ ...customNumericOperators ],
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                        <div className={`invalidPoNumber ${params.row.quantity}`}>
                            {params.row.quantity}
                        </div>
                    );
                } else {
                    return (
                        <div>
                            {params.row.quantity}
                        </div>
                    )
                }
            }
        },
        {
            field: "weight",
            headerName: "Weight",
            minWidth: 100,
            filterOperators: [ ...customNumericOperators ],
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                        <div className={`invalidPoNumber ${params.row.weight}`}>
                            {params.row.weight}
                        </div>
                    );
                } else {
                    return (
                        <div>
                            {params.row.weight}
                        </div>
                    )
                }
            }
        },
        {
            field: "equipment",
            headerName: "Equipment",
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <div>
                        { ShipmentUtils.getSpotmarketEquipments(params.row.metadata?.spotmarket?.equipments).join(", ") ?? '--' }
                    </div>
                );
            },
            valueGetter: (params) => ShipmentUtils.getSpotmarketEquipments(params.row.metadata?.spotmarket?.equipments) ?? '--',
        },
        {
            field: "pickups.originCity",
            headerName: "Origin City",
            minWidth: 200,
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                        <div className={`invalidPoNumber ${params.row.pickups[0]?.originCity}`}>
                            {params.row.pickups[0]?.originCity}
                        </div>
                    );
                } else {
                    return (
                        <div>
                            {params.row.pickups[0]?.originCity}
                        </div>
                    )
                }
            },
            valueGetter: (params) => params.row.pickups[0]?.originCity,
        },
        {
            field: "pickups.originState",
            headerName: "Origin State",
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <div>
                        { params.row.pickups[0]?.originState }
                    </div>
                );
            },
            valueGetter: (params) => params.row.pickups[0]?.originState,
        },
        {
            field: "pickups.originCtry",
            headerName: "Origin Country",
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <div>
                        { params.row.pickups[0]?.originCtry }
                    </div>
                );
            },
            valueGetter: (params) => params.row.pickups[0]?.originCtry,
        },
        {
            field: "pickups.targetShipEarly",
            headerName: "Pickup Date",
            minWidth: 200,
            filterOperators: [ ...getGridDateOperators() ],
            renderCell: (params) => {
                <div>
                    {fDateString(params.row.pickups[0]?.targetShipEarly)}
                </div>
            },
            // PASSES TO valueFormatter
            valueGetter: (params) => {
                return fDateString(params.row.pickups[0]?.targetShipEarly);
            },
        },
        {
            field: "stops.destCity",
            headerName: "Destination City",
            minWidth: 200,
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                        <div className={`invalidPoNumber ${params.row.stops[0]?.destCity}`}>
                            {params.row.stops[0]?.destCity}
                        </div>
                    );
                } else {
                    return (
                        <div>
                            {params.row.stops[0]?.destCity}
                        </div>
                    )
                }
            },
            valueGetter: (params) => params.row.stops[0]?.destCity,
        },
        {
            field: "stops.destState",
            headerName: "Dest. State",
            minWidth: 240,
            renderCell: (params) => {
                return (
                    <div>
                        { params.row.stops[0]?.destState }
                    </div>
                );
            },
            valueGetter: (params) => `${params.row.stops[0]?.destCtry}`,
        },
        {
            field: "stops.targetDeliveryEarly",
            headerName: "Delivery Date",
            minWidth: 200,
            filterOperators: [ ...getGridDateOperators() ],
            renderCell: (params) => {
                return (
                    <div>
                        {fDateString(params.row.stops[0]?.targetDeliveryEarly)}
                    </div>
                )
            },
            valueGetter: (params) => {
                return fDateString(params.row.pickups[0]?.targetDeliveryEarly);
            },
        },
    ];

    const actions_col = {
        field: "actions",
        headerName: "Actions",
        width: 150,
        renderCell: (params) => {
          return (
            <Box>
              { invalidShipments ? (
                <InvalidShipmentActions
                  params={params}
                  onApproveItem={onApproveItem}
                  onDenyItem={onDenyItem}
                />
              ) : (
                <ValidShipmentActions
                  params={params}
                  onDuplicate={(e) => e.stopPropagation()}
                  onEdit={(e) => e.stopPropagation()}
                />
              )}
            </Box>
          );
        },
    };

    if (invalidShipments) {
        cols.unshift(actions_col)
    } else {
        cols.push(actions_col)
    }
    
    return cols;
}

const ValidShipmentActions = ({ params, onDuplicate, onEdit }) => (
    <>
      <Tooltip title="Duplicate">
        <Button onClick={onDuplicate} href={`/shipments/${params.row._id}/edit?duplicate`}>
          <ControlPointDuplicate />
        </Button>
      </Tooltip>
      <Tooltip title="Edit">
        <Button onClick={onEdit} href={`/shipments/${params.row._id}/edit`}>
          <Edit />
        </Button>
      </Tooltip>
    </>
  );
  
  const InvalidShipmentActions = ({ params, onApproveItem, onDenyItem }) => (
    <>
      <Tooltip title="Approve">
        <Button onClick={(e) => { onApproveItem(e, params.row._id); }}>
          <Check />
        </Button>
      </Tooltip>
      <Tooltip title="Deny">
        <Button onClick={(e) => { onDenyItem(e, params.row._id); }}>
          <DoDisturb />
        </Button>
      </Tooltip>
    </>
  );

const Unavailable = () => (
    <Button disabled sx={{ fontWeight: 200 }}>Unavailable</Button>
)

const getBOLNumber = (shipment) => {
    const BOLNumber = shipment.integrations?.find(integration => (
        integration.target == 'banyan' &&
        integration.status == 'success' &&
        !!integration?.metadata?.bolNumber
    )
    )?.metadata.bolNumber;
    return BOLNumber || ''
}

const getCarrierShipment = (shipment) => {
    if (shipment.mode === 'LTL') {
        const carrier = shipment.integrations?.find(integration => (integration?.status == 'success'));
        return carrier?.metadata?.carrier ?? '--';
    } else {
        return 'Trinity Logistic';
    }
}

const getServiceShipment = (shipment) => {
    const allServices = [
        ...shipmentServices.find((servicePerMode) => servicePerMode.mode === "LTL").services,
        ...shipmentServices.find((servicePerMode) => servicePerMode.mode === "LTL").pickups,
        ...shipmentServices.find((servicePerMode) => servicePerMode.mode === "LTL").stops,
        ...shipmentServices.find((servicePerMode) => servicePerMode.mode === "TL").services,
        ...shipmentServices.find((servicePerMode) => servicePerMode.mode === "TL").pickups,
        ...shipmentServices.find((servicePerMode) => servicePerMode.mode === "TL").stops,
    ];

    if (shipment.services) {
        const shipmentServiceLabels = [...new Set(
            allServices
                .filter(service => shipment.services.hasOwnProperty(service.key) && shipment.services[service.key])
                .map(service => service.label)
        )];

        if (shipmentServiceLabels.length > 0) {
            return shipmentServiceLabels.join(", ");
        }
    }

    return '--';
}


const fDateString = (dateString) => {
    if (!dateString) return ' -- '
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${month}/${day}/${year} ${hours}:${minutes}`;
};