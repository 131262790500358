import {
  Card,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  } from "@mui/material";

import { equipments } from "src/models/spotmarketEquipments";
import { Controller, useFormContext } from "react-hook-form";

export function SpotmarketEquipmentsField(props) {

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const { control, getValues, watch } = useFormContext();

  return (
    <Card
      variant="outlined"
      sx={{
        py: "1.5rem",
        px: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
      }}
    >
      <CardHeader
        title={"Equipments"}
        sx={{
          "&.MuiCardHeader-root": { p: 0 },
        }}
      />
      <FormControl>
        <InputLabel id="equipments-label">Equipments</InputLabel>
        <Controller
          control={control}
          name="metadata.spotmarket.equipments"
          render={({ field }) => (
            <Select
              {...field}
              multiple
              label="Equipments"
              fullWidth
              value={field.value || []}
              MenuProps={MenuProps}
            >
              {equipments.length > 0 ? (
                equipments.map((equipment) => (
                  <MenuItem key={equipment.key} value={equipment.key}>
                    {equipment.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No equipments available</MenuItem>
              )}
            </Select>
          )}
        />
      </FormControl>
    </Card>
  );
}