import { ShipmentLocation } from "./shipmentLocation";

export class Location {
  name = null;
  addr1 = null;
  addr2 = null;
  city = null;
  state = null;
  zip = null;
  ctry = "USA";
  timezone = null;

  static toJson(location) {
    if (!location.refType || !location.refId) {
      location.refType = null;
      location.refId = null;
    }

    return location;
  }

  fromWarehouse(warehouse) {
    this.name = warehouse.name;
    this.addr1 = warehouse.location;
    this.zip = warehouse.zip;
    this.city = warehouse.city;
    this.timezone = warehouse.timezone;
    this.state = warehouse.state;
    // this.addr1 = warehouse.address;

    return this;
  }

  fromStop(stop) {
    this.name = `${stop.destName}`;
    this.addr1 = stop.destAddr1;
    this.addr2 = stop.destAddr2;
    this.city = stop.destCity;
    this.state = stop.destState;
    this.zip = stop.destZip;
    this.alternativeCities = stop.alternativeCities;
    if (!!stop.destCtry && stop.destCtry != this.ctry) {
      this.ctry = stop.destCtry;
    }
    return this;
  }

  fromPickup(pickup) {
    this.name = `${pickup.originName}`;
    this.addr1 = pickup.originAddr1;
    this.addr2 = pickup.originAddr2;
    this.city = pickup.originCity;
    this.state = pickup.originState;
    this.zip = pickup.originZip;
    this.alternativeCities = pickup.alternativeCities;
    if (!!pickup.originCtry && pickup.originCtry != this.ctry) {
      this.ctry = pickup.originCtry;
    }
    return this;
  }

  static fromShipmentLocation(location) {
    const instance = new Location();
    instance.name = location.name;
    instance.addr1 = location.address1;
    instance.addr2 = location.address2;
    instance.city = location.city;
    instance.state = location.state;
    instance.zip = location.zip;
    instance.ctry = location.country;
    return instance;
  }

  static toShipmentLocation(location, type) {
    return {
      type,
      name: location.name,
      address1: location.addr1,
      address2: location.addr2,
      country: location.ctry,
      state: location.state,
      zip: location.zip,
      city: location.city,
      contacts: location.contacts,
      needsAppointment: type === 'pickup' ? location.needsAppointmentPickup : location.needsAppointmentStop,
      loadingDock: location.loadingDock,
      forklift: location.forklift,
      packageSlip: location.packageSlip,
      targetEarly: location.targetEarly,
      targetLate: location.targetLate,
      timezone: location.timezone,
    };
  }

  static toShipment(location, type, isLocationFormatted) {
    const loc = this.toShipmentLocation(location, type);
    if (isLocationFormatted) {
      return loc;
    }

    if (type === 'pickup') {
      return ShipmentLocation.toPickup(loc);
    } else {
      return ShipmentLocation.toStop(loc);
    }
  }
}