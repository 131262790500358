import React, { useEffect, useMemo, useState } from 'react';
import { Button, Box, Card, Container, Grid, Typography } from '@mui/material';
import { useShipmentQueries } from './hooks/useShipmentQueries';
import { isEmpty, isEqual } from 'lodash';
import { dailySummaryColumns } from './constants/shipmentColumns';
import { spotmarketShipmentColumns } from "./constants/spotmarketShipmentsColumns";
import { ShipmentsDataGrid } from './components/ShipmentsDataGrid';
import { useParams } from 'react-router-dom';
import { Check, DoDisturb } from '@mui/icons-material';
import DialogConfirmForShipments from './components/details-drawer/DialogConfirmForShipments';
import { useSystem } from 'src/hooks/useSystem';
import { useTenant } from 'src/hooks/useTenant';

const initialPageState = {
    selectedCalendarDate: new Date().setHours(0, 0, 0, 0),
    isCalendarVisible: false,
}

export function PendingShipments() {
    const { socket } = useSystem();
    const [pageState, setPageState] = useState(initialPageState);
    const { tenant } = useTenant();
    const { shipmentId } = useParams();
    const [cachedShipments, setShipmentsCache] = useState([])

    const {
        getPendingShipments,
        isLoadingPendingShipments,
        isRefetchingPendingShipments,
        approveShipment,
        approveAllShipments,
        denyShipment,
        denyAllShipments,
        pendingShipments: shipments,
        onNewPendingShipment,
        onApproveDenyShipment,
    } = useShipmentQueries();

    useEffect(() => {
        if (!socket) return;
        const stopper = onNewPendingShipment((shipment) => {
            setShipmentsCache((prev) => [...prev, shipment]);
        });

        return () => stopper();
    }, [onNewPendingShipment]);

    useEffect(() => {
        if (!socket) return;

        const stopper = onApproveDenyShipment((shipmentIds) => {
            setShipmentsCache(prev =>
                prev.filter(sh => !sh._id.includes(shipmentIds))
            );
        });

        return () => stopper();
    }, [onApproveDenyShipment]);

    useEffect(() => { getPendingShipments() }, [])

    useEffect(() => {
        if (shipments) {
            setShipmentsCache(shipments)
        }
    }, [shipments])

    const [openDialogToApprove, setOpenDialogToApprove] = useState(false);
    const [openDialogToDeny, setOpenDialogToDeny] = useState(false);

    const [openDialogToApproveAll, setOpenDialogToApproveAll] = useState(false);
    const [openDialogToDenyAll, setOpenDialogToDenyAll] = useState(false);

    const [shipmentIdPending, setShipmentIdPending] = useState(null);

    const handleApproveShipment = (e, shipment) => {
        e.stopPropagation();
        setOpenDialogToApprove(true)
        setShipmentIdPending(shipment)
    }

    const handleDenyShipment = (e, shipment) => {
        e.stopPropagation();
        setOpenDialogToDeny(true)
        setShipmentIdPending(shipment)
    }

    const approveShipmentId = (e) => {
        // e.stopPropagation();
        approveShipment({ shipmentId: shipmentIdPending }, {
            onSuccess: () => {
                setShipmentsCache(prevShipments => 
                    prevShipments.filter(sh => sh._id !== shipmentIdPending)
                );
            }
        });
    }

    const denyShipmentId = (e) => {
        // e.stopPropagation();
        denyShipment({ shipmentId: shipmentIdPending }, {
            onSuccess: () => {
                setShipmentsCache(prevShipments => 
                    prevShipments.filter(sh => sh._id !== shipmentIdPending)
                );
            }
        });
    }

    const handleApproveAllShipments = (e) => {        
        setOpenDialogToApproveAll(true)
        setShipmentIdPending(null)
    };

    const handleDenyAllShipments = (e) => {        
        setOpenDialogToDenyAll(true)
        setShipmentIdPending(null)
    };

    const approveShipments = (e) => {        
        approveAllShipments();
    };

    const denyShipments = (e) => {        
        denyAllShipments();
    };

    let filteredData = useMemo(() => {
        let shipments = cachedShipments;
        if (!Array.isArray(shipments)) return [];

        if (pageState.isCalendarVisible && pageState.selectedCalendarDate) {
            return shipments.filter((shipment) => {
                const deliveryDate = new Date(
                    shipment.stops[0].targetDeliveryLate
                ).setHours(0, 0, 0, 0);

                const shipDate = new Date(
                    shipment.pickups[0].targetShipEarly
                ).setHours(0, 0, 0, 0);

                const currentDate = pageState.selectedCalendarDate;

                return isEqual(deliveryDate, currentDate) || isEqual(shipDate, currentDate);
            });
        }
        return shipments;

    }, [cachedShipments]);

    let columns = null
    if (!tenant.modules?.spotmarket)
        columns = dailySummaryColumns(null, tenant, true, handleApproveShipment, handleDenyShipment)
    else
        columns = spotmarketShipmentColumns(null, tenant, true, handleApproveShipment, handleDenyShipment)

    return <Container maxWidth="false">
        <Typography
            variant="h4"
            sx={{ mb: 1, mt: 3, textAlign: "center", display: "flex" }}
        >
            Pending Shipments
        </Typography>
        <Box sx={{ my: 4, display: { md: "none" } }} />
        <Grid container item xs={12}>
            <Card sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={handleApproveAllShipments}
                        sx={{ mr: 2 }}>
                        <Check sx={{ marginRight: '4px', fontSize: '16px' }} />
                        Approve All
                    </Button>
                    <Button
                        sx={{ mr: 2 }}
                        variant="outlined"
                        size="small"
                        onClick={handleDenyAllShipments}>
                        <DoDisturb sx={{ marginRight: '4px', fontSize: '16px' }} />
                        Deny All
                    </Button>
                </Box>
                <section className="dataTable">
                    <ShipmentsDataGrid
                        shipments={filteredData}
                        isLoadingData={isLoadingPendingShipments || isRefetchingPendingShipments}
                        columns={columns}
                        selectedItemId={shipmentId}
                        disableMultipleRowSelection={false}
                        getTreeDataPath={(row) => row.hierarchy || []}
                        defaultGroupingExpansionDepth={1}
                        modelKey={ tenant.modules?.spotmarket ? "spotmarketPendingShipments" : "pendingShipments" }
                        isInvalidShipment
                    />
                </section>
            </Card>
        </Grid>

        <DialogConfirmForShipments
            title={"Approve Shipment"}
            text={"Are you sure you want to approve this shipment?"}
            onConfirm={(e) => {
                setOpenDialogToApprove(false);
                approveShipmentId(e);
            }}
            onClose={() => { setOpenDialogToApprove(false) }}
            open={openDialogToApprove} />

        <DialogConfirmForShipments
            title={"Deny Shipment"}
            text={"Are you sure you want to deny and archive this shipment?"}
            onConfirm={(e) => {
                setOpenDialogToDeny(false);
                denyShipmentId(e);
            }}
            onClose={() => { setOpenDialogToDeny(false) }}
            open={openDialogToDeny} />

        <DialogConfirmForShipments
            title={"Approve All Shipments"}
            text={"Are you sure you want to approve all the shipments?"}
            onConfirm={(e) => {
                setOpenDialogToApproveAll(false);
                approveShipments(e);
            }}
            onClose={() => { setOpenDialogToApproveAll(false) }}
            open={openDialogToApproveAll} />

        <DialogConfirmForShipments
            title={"Deny All Shipments"}
            text={"Are you sure you want to deny all the shipment?"}
            onConfirm={(e) => {
                setOpenDialogToDenyAll(false);
                denyShipments(e);
            }}
            onClose={() => { setOpenDialogToDenyAll(false) }}
            open={openDialogToDenyAll} />
    </Container>
}