import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  TextField
} from "@mui/material";

import { Controller, useFormContext } from "react-hook-form";
import { useAuth } from "src/hooks/useAuth";
import { NumericFormat } from 'react-number-format';
import { FormInput } from "src/components/form/FormInput";
import Typography from "src/theme/overrides/Typography";
import { KeyboardDoubleArrowRight } from "@mui/icons-material";

export function SpotmarketRateFields(props) {
  const { session } = useAuth();
  const { control, getValues, watch } = useFormContext();

  return (
    <Card
      variant="outlined"
      sx={{
        py: "1.5rem",
        px: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
      }}
    >
      <CardHeader
        title={"Rates & Additional Info"}
        sx={{
          "&.MuiCardHeader-root": { p: 0 },
        }}
      />
      <CardContent
        sx={{
          "&.MuiCardContent-root": { p: 0, py: '1rem' },
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",

        }}
      >

        {/* SPOTMARKET FIELDS */}
        <Stack sx={{ mb: 3 }}>
          <FormInput
            label="Customer"
            name={`customer`}
            type="text"
            fullWidth
          />
        </Stack>
        <Stack 
          sx={{ mb: 3 }}
          direction={{ xs: "column", md: "row" }} 
          spacing={2}
        >
          {/* Posted Rate */}
          <Controller
            name="metadata.spotmarket.postedRate"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Posted Rate"
                fullWidth
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: NumericFormat,
                }}
                inputProps={{
                  thousandSeparator: false,
                  decimalSeparator: ".",
                  decimalScale: 2,
                  fixedDecimalScale: true,
                }}
              />
            )}
          />
        </Stack>

        {/* <Typography variant="h6" component="h1">
          Truck Rate Range
        </Typography> */}

        {/* Truck Rate Range */}
        <Stack 
          direction={{ xs: "column", md: "row" }} 
          spacing={2}
        >
          <Controller
            name="metadata.spotmarket.truckRateRange.min"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Initial Truck Rate"
                fullWidth
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: NumericFormat,
                }}
                inputProps={{
                  thousandSeparator: false,
                  decimalSeparator: ".",
                  decimalScale: 2,
                  fixedDecimalScale: true,
                }}
              />
            )}
          />

          <KeyboardDoubleArrowRight 
            sx={{ 
              alignSelf: 'center',
              color: (theme) => theme.palette.primary.main,
            }} 
          />

          <Controller
            name="metadata.spotmarket.truckRateRange.max"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Maximum Truck Rate"
                fullWidth
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: NumericFormat,
                }}
                inputProps={{
                  thousandSeparator: false,
                  decimalSeparator: ".",
                  decimalScale: 2,
                  fixedDecimalScale: true,
                }}
              />
            )}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}
