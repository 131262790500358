import config from "src/config";
import {
  BaseService
} from "./base.service";
import { method } from "lodash";

export class ShipmentService extends BaseService {
  baseUrl = `${config.apiUrl}/shipment`;

  getShipments = async (filter) => {
    const uri = new URL(`${this.baseUrl}`);
    if (filter) uri.searchParams.append("filter", filter);

    const response = await fetch(uri.toString(), this.header());
    return await response.json();
  };

  getBackofficeShipmentsByStatus = async (status) => {
    const uri = new URL(`${this.baseUrl}/backoffice`);
    uri.searchParams.append("status", status);

    const response = await fetch(uri.toString(), this.header());
    return await response.json();
  }

  // COMBINED SHIPMENTS
  createFromShipments = async (combinedShipment) => {
    const uri = new URL(`${this.baseUrl}/combine`);
    const response = await fetch(
      uri.toString(),
      this.header({
        method: 'POST',
        body: JSON.stringify(combinedShipment),
      })
    );

    return await response.json();
  }

  exportSpotmarketDatTs = async (shipmentIds, mode) => {    
    const uri = new URL(`${config.apiUrl}/spotmarket/export/`);

    const response = await fetch(
        uri.toString(),
        this.header({
            method: 'POST',
            body: JSON.stringify({ shipmentIds, mode })
        })
    );

    if (!response.ok) throw new Error(`Erro na exportação: ${response.statusText}`);

    return await response.blob();
  };

  validateShipment = async (shipmentId) => {
    const uri = new URL(`${this.baseUrl}/${shipmentId}/validate`);

    const response = await fetch(
      uri.toString(),
      this.header({
        method: 'POST',
        body: JSON.stringify({
          shipmentId
        })
      })
    );
    return await response.json();
  }

  uncombine = async (shipmentId) => {
    const uri = new URL(`${this.baseUrl}/${shipmentId}/uncombine`);

    const response = await fetch(
      uri.toString(),
      this.header({
        method: 'POST',
      })
    );

    if (response.ok) {
      return true;
    }

    return await response.json();
  }

  getShipment = async (shipmentId) => {
    const uri = new URL(`${this.baseUrl}/${shipmentId}`);

    const response = await fetch(uri.toString(), this.header());
    return await response.json();
  };

  getOrderDetails = async (shipmentId) => {
    const uri = new URL(`${this.baseUrl}/${shipmentId}/orders`);
    const response = await fetch(uri.toString(), this.header());
    return await response.json();
  }

  resetShipmentStatus = async (shipmentId) => {
    const uri = new URL(`${this.baseUrl}/${shipmentId}/reset-status`);

    const response = await fetch(uri.toString(), this.header({
      method: "PUT"
    }));
    return await response.json();
  };

  createShipment = async (data) => {
    const uri = new URL(config.apiUrl + '/shipment');

    const response = await fetch(
      uri.toString(),
      this.header({
        method: 'POST',
        body: JSON.stringify(data)
      })
    );

    const shipmentResponseJson = await response.json();

    if (!response.ok) {
      const errorMessage = getErrorMessage(shipmentResponseJson);
      throw new Error(`Error while creating shipment: \n ${errorMessage}`);
    }
    return shipmentResponseJson;
  }

  createSpotmarketShipment = async (data) => {
    const uri = new URL(config.apiUrl + '/spotmarket/shipment');

    const response = await fetch(
      uri.toString(),
      this.header({
        method: 'POST',
        body: JSON.stringify(data)
      })
    );

    const shipmentResponseJson = await response.json();

    if (!response.ok) {
      const errorMessage = getErrorMessage(shipmentResponseJson);
      throw new Error(`Error while creating shipment: \n ${errorMessage}`);
    }
    return shipmentResponseJson;
  }

  consolidateShipment = async (shipmentId) => {
    const uri = new URL(config.apiUrl + `/spotmarket/shipment/${shipmentId}/consolidate`);

    const response = await fetch(
      uri.toString(),
      this.header({
        method: 'POST',
      })
    );

    const shipmentResponseJson = await response.json();

    if (!response.ok) {
      const errorMessage = getErrorMessage(shipmentResponseJson);
      throw new Error(`Error while consolidating shipment: \n ${errorMessage}`);
    }
    return shipmentResponseJson;
  }

  deleteShipment = async (shipmentId) => {
    const uri = new URL(config.apiUrl + '/shipment/' + shipmentId);

    const response = await fetch(
      uri.toString(),
      this.header({
        method: 'DELETE',
      })
    );

    return await response.json();
  }

  updateShipment = async (shipmentId, data) => {
    const uri = new URL(config.apiUrl + `/shipment/${shipmentId}`);

    const response = await fetch(
      uri.toString(),
      this.header({
        body: JSON.stringify(data),
        method: "PUT"
      })
    );

    return await response.json();
  };

  updateSpotmarketShipment = async (shipmentId, data) => {
    const uri = new URL(config.apiUrl + `/spotmarket/shipment/${shipmentId}`);

    const response = await fetch(
      uri.toString(),
      this.header({
        body: JSON.stringify(data),
        method: "PUT"
      })
    );

    return await response.json();
  };

  approveShipment = async (shipmentId) => {
    const uri = new URL(`${this.baseUrl}/${shipmentId}/approve`);

    const response = await fetch(
      uri.toString(),
      this.header({
        method: 'POST',
        body: JSON.stringify({
          shipmentId
        })
      })
    );
    return await response.json();
  }

  approveAllShipments = async () => {
    const uri = new URL(`${this.baseUrl}/approve-all`);

    const response = await fetch(
      uri.toString(),
      this.header({
        method: 'POST',
      })
    );
    return await response.json();
  }

  denyAllShipments = async () => {
    const uri = new URL(`${this.baseUrl}/deny-all`);

    const response = await fetch(
      uri.toString(),
      this.header({
        method: 'POST',
      })
    );
    return await response.json();
  }

  denyShipment = async (shipmentId) => {
    const uri = new URL(`${this.baseUrl}/${shipmentId}/deny`);

    const response = await fetch(
      uri.toString(),
      this.header({
        method: 'POST',
        body: JSON.stringify({
          shipmentId
        })
      })
    );
    return await response.json();
  }

  pendingShipment = async (shipmentId, rate = null, approvement = null) => {
    const uri = new URL(`${this.baseUrl}/${shipmentId}/pending`);

    const response = await fetch(
      uri.toString(),
      this.header({
        method: 'POST',
        body: JSON.stringify({
          shipmentId,
          rate: rate,
          approvement: approvement
        })
      })
    );
    return await response.json();
  }

  // UPLOAD FILES
  uploadShipmentFile = async (shipmentId, file, fileType) => {
    const uri = new URL(`${this.baseUrl}/${shipmentId}/file`);
    uri.searchParams.append("context", fileType);

    const formData = new FormData();
    formData.append("file", file);

    const response = await fetch(uri.toString(), this.header({
      method: "POST",
      body: formData,
    }, true));

    const responseJson = await response.json();

    if (!response.ok) {
      const errorMessage = getErrorMessage(responseJson);
      throw new Error(`Error uploading ${fileType} file: ${errorMessage}`);
    }
    return responseJson;
  };

  // UPLOAD PACKAGE SLIP FILE
  uploadPackageFile = async (shipmentId, file, itemId) => {
    const uri = new URL(`${this.baseUrl}/${shipmentId}/packageslip`);
    uri.searchParams.append("itemId", itemId);

    const formData = new FormData();
    formData.append("file", file);

    const response = await fetch(uri.toString(), this.header({
      method: "POST",
      body: formData,
    }, true));

    const responseJson = await response.json();

    if (!response.ok) {
      const errorMessage = getErrorMessage(responseJson);
      throw new Error(`Error uploading package file: ${errorMessage}`);
    }
    return responseJson;
  };

  deleteShipmentFile = async (shipmentId, fileType) => {
    const uri = new URL(`${this.baseUrl}/${shipmentId}/file`);
    uri.searchParams.append("context", fileType);

    const response = await fetch(uri.toString(), this.header({
      method: "DELETE",
    }, true));

    const responseJson = await response.json();

    if (!response.ok) {
      const errorMessage = getErrorMessage(responseJson);
      throw new Error(`Error deleting ${fileType} file: ${errorMessage}`);
    }

    return responseJson;
  }

  deletePackageFile = async (shipmentId, itemId) => {
    const uri = new URL(`${this.baseUrl}/${shipmentId}/packageslip`);
    uri.searchParams.append("itemId", itemId);

    const response = await fetch(uri.toString(), this.header({
      method: "DELETE",
    }, true));

    const responseJson = await response.json();

    if (!response.ok) {
      const errorMessage = getErrorMessage(responseJson);
      throw new Error(`Error deleting package slip file: ${errorMessage}`);
    }

    return responseJson;
  }

  // UPLOAD ATTACHMENT FILE
  uploadAttachmentFile = async (shipmentId, file) => {
    const uri = new URL(`${this.baseUrl}/${shipmentId}/attachment`);
  
    const formData = new FormData();
    formData.append("file", file);
  
    const response = await fetch(uri.toString(), this.header({
      method: "POST",
      body: formData,
    }, true));
  
    const responseJson = await response.json();
  
    if (!response.ok) {
      const errorMessage = getErrorMessage(responseJson);
      throw new Error(`Error uploading package file: ${errorMessage}`);
    }
    return responseJson;
  };

  // DELETE ATTACHMENT FILE
  deleteAttachmentFile = async (shipmentId) => {
    const uri = new URL(`${this.baseUrl}/${shipmentId}/attachment`);    

    const response = await fetch(uri.toString(), this.header({
      method: "DELETE",
    }, true));

    const responseJson = await response.json();

    if (!response.ok) {
      const errorMessage = getErrorMessage(responseJson);
      throw new Error(`Error deleting package slip file: ${errorMessage}`);
    }

    return responseJson;
  }


  // LOGISTIC
  async validateShipment(shipmentId, carrierName) {
    const featureUrl = "/logistic/validate";
    const uri = new URL(
      `${this.baseUrl}/:shipmentId${featureUrl}`.replace(
        ":shipmentId",
        shipmentId
      )
    );
    uri.searchParams.append('carrierName', carrierName)

    const response = await fetch(uri.toString(), this.header({
      method: "POST"
    }));
    const data = await response.json();
    if (response?.status == 200)
      return data;
    else throw new Error(data.message ? data.message : response.statusText);
  }
  async getCarrierQuotations(shipmentId, bypass = false) {
    const featureUrl = "/logistic/quotes";
    const uri = new URL(
      `${this.baseUrl}/:shipmentId${featureUrl}`.replace(
        ":shipmentId",
        shipmentId
      )
    );

    const response = await fetch(uri.toString(), this.header({
      method: 'POST',
      body: JSON.stringify({bypass})
    }));

    if (response?.status == 200)
      return await response.json();
    else throw new Error(response.statusText)
  }

  async getShipmentQuotations(shipmentId) {
    const featureUrl = "/logistic/quotes";
    const uri = new URL(
      `${this.baseUrl}/:shipmentId${featureUrl}`.replace(
        ":shipmentId",
        shipmentId
      )
    );

    const response = await fetch(uri.toString(), this.header({
      method: "GET",
    }));

    if (response?.status == 200)
      return await response.json();
    else throw new Error(response.statusText)
  }

  async confirmShipment(shipmentId, quotationIndex = null, appointmentTimePickup = null, appointmentTimeStop = null, bypass = false) {
    const featureUrl = "/logistic/confirm";
    const uri = new URL(
      `${this.baseUrl}/:shipmentId${featureUrl}`.replace(
        ":shipmentId",
        shipmentId
      )
    );

    const body = {
      shipmentId
    }

    if (quotationIndex !== null) body.quotationIndex = quotationIndex;
    body.bypass = bypass;

    if (appointmentTimePickup || appointmentTimeStop) {
      body.appointmentTimePickup = appointmentTimePickup
      body.appointmentTimeStop   = appointmentTimeStop
    }

    const response = await fetch(uri.toString(), this.header({
      method: "POST",
      body: JSON.stringify(body)
    }));

    const data = await response.json();
    return data;
  }

  async importIntegrationFile(shipmentId, loadId, context) {

    const uri = new URL(`${this.baseUrl}/${shipmentId}/load/${loadId}/import/${context}`);

    const response = await fetch(uri.toString(), this.header());
    const data = await response.json()    

    if (!response.ok) {
      const error = data.message || response.statusText;

      throw new Error(`${error}`)
    }
    
    return data;
  }

  async getHistoryById(shipmentId) {
    const uri = new URL(`${this.baseUrl}/:shipmentId/history`.replace(":shipmentId", shipmentId));
    const response = await fetch(uri.toString(), this.header());
    const data = await response.json();
    return data
  }
}

function getErrorMessage(error) {
  let errorMessage;

  if (error.message)
    errorMessage = error.message;
  if (error.msg)
    errorMessage = error.msg;
  if (error.messages) {
    if (Array.isArray(error.messages)) {
      errorMessage = error.errors.join(', ');
    }
  }
  if (error.errors && Array.isArray(error.errors)) {
    errorMessage = error.errors.map(error => error.msg).join(', ');
  }

  return errorMessage;
}