import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { StandardDialog } from "src/components/dialog/Dialog";

function DialogExportOrders({open, onClose, title, content}) {
    return (
        <StandardDialog isOpen={open} onClose={onClose}>
            <Box sx={{ width: '100%' }}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {content}
                    </DialogContentText>                    
                </DialogContent>
                <DialogActions sx={{ pr: 6, pb: 4 }}>
                    <Button variant="text" onClick={() => onClose()}>Close</Button>                    
                </DialogActions>
            </Box>
        </StandardDialog>
    );
}

export default DialogExportOrders;