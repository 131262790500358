import { DateTimePicker } from "@mui/x-date-pickers-pro";
import { Controller, useFormContext } from "react-hook-form";

import { Typography, Stack, Box, Grid, MenuItem, FormControlLabel, Checkbox } from "@mui/material";
import { FormInput } from "src/components/form/FormInput";
import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";

import { getDayjsDate } from "src/utils/formatTime";

import { usaStates } from "../../../warehouses/constants/usaStates";
import { countries } from "../../constants/countries";
import { canadaStates } from "src/pages/warehouses/constants/canadaStates";
import { defaultTimezone, timezones } from "src/utils/timezones";
import { useAuth } from "src/hooks/useAuth";
import { mexicoStates } from "src/pages/warehouses/constants/mexicoState";
import { useTenant } from "src/hooks/useTenant";
import { LocationService } from "src/api/location/location.service";
import { debounce } from "lodash";
import { useSnackbar } from "src/hooks/useSnackbar";
import { useCityQueries } from "src/pages/city/hooks/useCityQueries";

export function StopAddressFields({ itemIndex = 0 }) {
  const {
    control,
    formState: { errors },
    getValues, setValue,
    watch,
  } = useFormContext();

  const { 
    alternativeCities, 
    getCitiesFromLocation 
  } = useCityQueries();

  const [minEarlyDate, setMinEarlyDate] = useState(dayjs());
  const [minLateDate, setMinLateDate] = useState(dayjs());
  const abortControllerRef = useRef(null);
  const [locations, setLocation] = useState([]);
  const [tz, setTz] = useState(null);

  const { session } = useAuth();
  const { tenant } = useTenant();

  const service = new LocationService(session.token, session.tenant._id);
  const { setSnackbarMessage } = useSnackbar();  

  const debounceEffect = debounce((location) => {
    if (location?.length && location != "") {
      const fetchData = async () => {
        setLocation([]);
        abortControllerRef.current?.abort();
        abortControllerRef.current = new AbortController();
        const locationType = 'stops'
        const name = `stops.${itemIndex}.originName`
        try {
          const validatorBody = {
            name,
            value: location,
            isDelivery: locationType === 'delivery',
          };

          const result = await service.search(
            validatorBody,
            abortControllerRef.current?.signal
          );

          // If fetch is successful, update options
          if ((result.default && result.default.length > 0) || (result.search && result.search.length > 0)) {
            const data = [...result.default, ...result.search];
            setLocation(data);
          }              
        } catch (err) {
          // Handle fetch errors
          if (err.name === "AbortError") {
            // Fetch was aborted
            console.log("Fetch was aborted.");
          } else {                

            // Fetch error occurred
            setSnackbarMessage({
              message: err.message,
              severity: "error",
            });
          }
        }
      };
      fetchData();
    }
  }, 1500); 

  useEffect(() => {
    setMinEarlyDate(
      getDayjsDate(getValues(`pickups.${itemIndex}.targetShipEarly`))
    );
    setMinLateDate(
      getDayjsDate(getValues(`pickups.${itemIndex}.targetShipEarly`))
    );
  }, [watch(`pickups.${itemIndex}.targetShipEarly`)]);

  useEffect(() => {
    setMinLateDate(
      getDayjsDate(getValues(`stops.${itemIndex}.targetDeliveryEarly`))
    );
  }, [watch(`stops.${itemIndex}.targetDeliveryEarly`)]);

  useEffect(() => {
    const timezone = getValues(`stops.${itemIndex}.timezone`);
    if (timezone) setTz(
      timezones.find(
        (t) => t.name === (timezone || defaultTimezone)
      )
    );
  }, [watch(`stops.${itemIndex}.timezone`)]);  

  useEffect(() => {
    const name = getValues(`stops.${itemIndex}.destName`);
    if (name) {                  
      debounceEffect(getValues(`stops.${itemIndex}.destName`))            
    }
  }, [watch(`stops.${itemIndex}.destName`)]);
  
  const getStates = () => {
    const country = getValues(`stops.${itemIndex}.destCtry`);
    if (country === 'CA') {
      return canadaStates;
    } else if (country === 'MX') {
      return mexicoStates;
    } else {
      return usaStates;
    }
  }

  useEffect(() => {
    const validateFields = debounce(() => {
      const city = getValues(`stops.${itemIndex}.destCity`);
      const state = getValues(`stops.${itemIndex}.destState`);
      const country = getValues(`stops.${itemIndex}.destCtry`);
 
      if (city && state && country) {
        getCitiesFromLocation({
          city,
          state,
          ctry: country,
        })
      }
    }, 1500);
  
    validateFields();
    return () => validateFields.cancel();
  }, [
    watch(`stops.${itemIndex}.destCity`),
    watch(`stops.${itemIndex}.destState`),
    watch(`stops.${itemIndex}.destCtry`)
  ]);

  const [states, setStates] = useState(getStates());

  useEffect(() => {
    setStates(getStates());

  }, [watch(`stops.${itemIndex}.destCtry`)]);

  useEffect(() => {
    if (!states.some(state => state.acronym == getValues(`stops.${itemIndex}.destState`)))
      setValue(`stops.${itemIndex}.destState`, '');

  }, [states]);

  const country = useMemo(() => {
    setStates(getStates());
    const country = getValues(`stops.${itemIndex}.destCtry`);
    return country ?? 'USA';
  }, [watch(`stops.${itemIndex}.destCtry`)]);

  return (
    <Stack spacing={3} width={"100%"}>
      <Typography>Add Stop Address</Typography>
      <FormInput
        name={`stops.${itemIndex}.destCity`}
        label="City"
        type="text"
        required
      />
      <FormInput
        name={`stops.${itemIndex}.destState`}
        label="State"
        select
        required
      >
        {states.map((state) => {
          return (
            <MenuItem key={state.acronym} value={state.acronym}>
              {state.name}
            </MenuItem>
          );
        })}
      </FormInput>
      <FormInput
        name={`stops.${itemIndex}.destCtry`}
        label="Country"
        select
        required
      >
        {countries.map((state) => {
          return (
            <MenuItem key={state.acronym} value={state.acronym}>
              {state.name}
            </MenuItem>
          );
        })}
      </FormInput>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Controller
          control={control}
          name={`stops.${itemIndex}.targetDeliveryEarly`}
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <DateTimePicker
              {...field}
              value={getDayjsDate(field.value)}
              onChange={(date) => {
                field.onChange(date);
              }}
              timezone="UTC"
              minDate={minEarlyDate}
              inputRef={ref}
              size="small"
              sx={{ width: "100%" }}
              label="Delivery Date"
            />
          )}
        />
        {tz && (
          <Typography component="h6" sx={{ mt: 1 }}>
            Timezone: {tz.name}{" "}
            <br />
            <small>{tz.rawFormat}</small>
          </Typography>
        )}
      </Box>
      <Box>
        <Typography fontWeight={600} pb={2}>
          Alternative Cities
        </Typography>
        {alternativeCities?.length > 0 ? (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {alternativeCities.map((city) => (
              <FormControlLabel
                key={city._id}
                control={
                  <Checkbox
                    checked={
                      watch(`stops.${itemIndex}.alternativeCities`)?.some(
                        (altCity) => altCity._id === city._id
                      ) || false
                    }
                    onChange={(event) => {
                      const selectedCities = watch(`stops.${itemIndex}.alternativeCities`) || [];
                      if (event.target.checked) {
                        setValue(
                          `stops.${itemIndex}.alternativeCities`,
                          [...selectedCities, city]
                        );
                      } else {
                        setValue(
                          `stops.${itemIndex}.alternativeCities`,
                          selectedCities.filter((selectedCity) => selectedCity._id !== city._id)
                        );
                      }
                    }}                    
                  />
                }
                label={`${city.city}, ${city.state}, ${city.ctry}`}
              />
            ))}
          </Box>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No alternative cities available.
          </Typography>
        )}
      </Box>
    </Stack>
  );
}
