import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useShipmentDetails } from "../../../context/ShipmentDetailsContext";
import { valueGetter, currencyGetter } from "./DetailsDrawer";
import { ShipmentAttachments } from "./ShipmentAttachments";
import { Error, WarningAmber } from "@mui/icons-material";
import { useAuth } from "src/hooks/useAuth";
import { ShipmentUtils } from "src/utils";
import { useTenant } from "src/hooks/useTenant";

export function ShipmentBasicInfo({ }) {
  const { shipment } = useShipmentDetails();
  const { tenant } = useTenant();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        gap: "0.75rem",
      }}
    >
      {
        // This checks if shipment.services.hazardousCargoCharge is truthy. If it is, the Box component is rendered, otherwise, nothing happens
        shipment.services?.hazardousCargoCharge && (
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"start"}
            alignItems={"center"}
            gap={"4px"}
            color={(theme) => theme.palette.warning.dark}
          >
            <WarningAmber />
            <Typography variant="h6">This is a hazmat shipment</Typography>
          </Box>
        )
      }
      {
        // This checks if shipment.services.expedited is truthy. If it is, the Box component is rendered, otherwise, nothing happens
        shipment.services?.expedited && (
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"start"}
            alignItems={"center"}
            gap={"4px"}
            color={(theme) => theme.palette.warning.dark}
          >
            <WarningAmber />
            <Typography variant="h6">This is a expedited shipment</Typography>
          </Box>
        )
      }
      {
        // This checks if shipment.services.hazardousCargoCharge is truthy. If it is, the Box component is rendered, otherwise, nothing happens
        shipment.services?.guaranteedDelivery && (
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"start"}
            alignItems={"center"}
            gap={"4px"}
            color={(theme) => theme.palette.warning.dark}
          >
            <WarningAmber />
            <Typography variant="h6">This is a guaranteed delivery shipment</Typography>
          </Box>
        )
      }
      {
        // This checks if shipment.services.hazardousCargoCharge is truthy. If it is, the Box component is rendered, otherwise, nothing happens
        shipment.metadata?.invalidPoNumber && (
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"start"}
            alignItems={"center"}
            gap={"4px"}
            color={(theme) => theme.palette.error.dark}
          >
            <Error />
            <Typography variant="h6">This shipment has an invalid PO Number</Typography>
          </Box>
        )
      }
      <Box sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          lg: 'row',
          xl: 'row',
        },
        gap: '1rem',
        justifyContent: 'space-between'
      }}>
        {/* BASIC INFO */}
        <Card
          variant="outlined"
          sx={{
            flex: 1,
            py: "0.5rem",
            px: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "0.75rem",
            maxWidth: { lg: "auto" }
          }}
        >
          <CardHeader
            component={() => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: 'column' },
                  justifyContent: "space-between",
                  py: "0.25rem",
                  gap: '0.6rem'
                }}
              >
                <Box>
                  <Typography
                    variant="h1"
                    fontWeight={600}
                    sx={{
                      "&.MuiTypography-h1": {
                        fontSize: "1.2rem"
                      }
                    }}
                  >
                    {shipment.primaryReference}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: '0.5rem', mt: '0.6rem' }}>
                    <Typography sx={{ fontWeight: '500' }}>Special Instructions: </Typography>
                    <Typography>{shipment.specialInstructions?.length ? shipment.specialInstructions : 'N/A'}</Typography>
                  </Box>
                </Box>
                <Typography color={"GrayText"}>
                  {`Created by ${shipment.created.by.firstname} ${shipment.created.by.lastname}`}
                </Typography>
              </Box>
            )}
            sx={{
              "&.MuiCardHeader-root": {
                p: 0,
              },
            }}
          />
          <Divider />
          <CardContent
            sx={{
              "&.MuiCardContent-root": {
                p: 0,
              },
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '1.5rem' }}>
              <ColumnOne shipment={shipment} />
              <ColumnTwo shipment={shipment} />
            </Box>
          </CardContent>
        </Card>
        
        {/* ATTACHMENTS */}
        { !tenant.modules?.spotmarket && (
          <ShipmentAttachments 
            sx={{ 
              flexShrink: 0,
              minWidth: {
                lg: 'fit-content'
              },
            }} 
          />
        )}
      </Box>
    </Box>
  );
}

function ColumnOne({ shipment }) {
  const { session } = useAuth();
  const { tenant } = useTenant();

  return (
    <Box sx={{ width: '100%' }}>
      {(tenant?.modules?.spotmarket && shipment.metadata?.rate) &&
        <Box sx={{
          display: "flex",
          gap: '0.5rem',
          py: "0.25rem"
        }}>
          <Typography>Rate: </Typography>
          <Typography fontWeight={600}>
            ${valueGetter(shipment.metadata?.rate)}
          </Typography>
        </Box>
      }
      { /* PO Number */}
      {shipment.poNumber &&
        <Box sx={{
          display: "flex",
          gap: '0.5rem',
          py: "0.25rem"
        }}>
          <Typography> {ShipmentUtils.getPONumberLabel(shipment?.tenant)}: </Typography>
          <Typography fontWeight={600}>
            {valueGetter(shipment.poNumber)}
          </Typography>
        </Box>
      }
      { /* Mode */}
      <Box sx={{
        display: "flex",
        gap: '0.5rem',
        py: "0.25rem"
      }}>
        <Typography>Mode: </Typography>
        <Typography fontWeight={600}>
          {valueGetter(shipment.mode)}
        </Typography>
      </Box>
      { /* Status */}
      <Box sx={{
        display: "flex",
        gap: '0.5rem',
        py: "0.25rem"
      }}>
        <Typography>Status: </Typography>
        <Typography fontWeight={600}>
          {valueGetter(shipment.status)}
        </Typography>
      </Box>
      { /* Quantity */}
      <Box sx={{
        display: "flex",
        gap: '0.5rem',
        py: "0.25rem"
      }}>
        <Typography>Quantity: </Typography>
        <Typography fontWeight={600}>
          {valueGetter(shipment.quantity)}
        </Typography>
      </Box>
      {
        /* Weight */
      }
      <Box sx={{
        display: "flex",
        gap: '0.5rem',
        py: "0.25rem"
      }}>
        <Typography>Weight:</Typography>
        <Typography fontWeight={600}>
          {`${valueGetter(shipment.weight)}/${valueGetter(shipment.weightUOM, "lb")}`}
        </Typography>
      </Box>
      <Box sx={{
        display: "flex",
        gap: '0.5rem',
        py: "0.25rem"
      }}>
        <Typography>Dimensions:</Typography>
        <Tooltip title="Length X  Width X Height - Unit">
          <Box sx={{
            display: "flex",
            gap: 0.5
          }}>
            <Typography fontWeight={600}>
              {valueGetter(shipment.length)}
            </Typography>
            <Typography color={"GrayText"}>X</Typography>
            <Typography fontWeight={600}>
              {valueGetter(shipment.width)}
            </Typography>
            <Typography color={"GrayText"}>X</Typography>
            <Typography fontWeight={600}>
              {valueGetter(shipment.height)}
            </Typography>
            <Typography fontWeight={600}>
              {valueGetter(shipment.dimensionUOM)}
            </Typography>
          </Box>
        </Tooltip>
      </Box>

      {/* Equipment */}
      { !tenant.modules?.spotmarket && (
      <Box sx={{
        display: "flex",
        gap: '0.5rem',
        py: "0.25rem"
      }}>
        <Typography>Equipment:</Typography>
        <Typography fontWeight={600}>
          {valueGetter(ShipmentUtils.getEquipmentFromShipment(shipment))}
        </Typography>
      </Box>
      )}

      {/* Transfer Number */}
      {shipment.transferNumber && <Box sx={{
        display: "flex",
        gap: '0.5rem',
        py: "0.25rem"
      }}>
        <Typography>Transfer Number: </Typography>
        <Typography fontWeight={600}>
          {valueGetter(shipment.transferNumber)}
        </Typography>
      </Box>}

      { /* Customer */}
      {shipment.customer && <Box sx={{
        display: "flex",
        gap: '0.5rem',
        py: "0.25rem"
      }}>
        <Typography>Customer:</Typography>
        <Typography fontWeight={600}>
          {valueGetter(shipment.customer)}
        </Typography>
      </Box>}

    </Box>
  );
}

function ColumnTwo({ shipment }) {
  const { session } = useAuth();
  const { tenant } = useTenant();

  const isBackOfficeUser = session.permissions.IsBackOffice;

  const orderNumb = () => {
    let orderNumber = ''
    if (session.tenant.code === 'COX') {
      orderNumber = 'Shipper Reference Number:'
    } else {
      orderNumber = 'Order Number:'
    }

    return orderNumber
  }

  const getCarrierShipment = () => {

    if (shipment.mode === 'LTL') {
      const carrier = shipment.integrations?.find(integration => (integration.status == 'success'));

      if (carrier && carrier.metadata) {
        return carrier.metadata.carrier;
      } else {
        return '--';
      }
    } else {
      return 'Trinity Logistic';
    }
  };

  const loadId = shipment.integrations.find(
    (integration) => !!integration?.metadata?.loadId
  )?.metadata?.loadId;

  return (
    <Box sx={{ width: '100%' }}>
      { /* Combined Primary References */}
      {shipment.combined && <Box sx={{
        display: "flex",
        gap: '0.5rem',
        py: "0.25rem"
      }}>
        <Typography>Combined From: </Typography>
        <Typography fontWeight={600}>
          {shipment.combinedFrom?.map(shipment => valueGetter(shipment.primaryReference)).join(', ')}
        </Typography>
      </Box>}

      { /* Shipment Number */}
      {shipment.shipmentNumber && <Box sx={{
        display: "flex",
        gap: '0.5rem',
        py: "0.25rem"
      }}>
        <Typography>Shipment Number: </Typography>
        <Typography fontWeight={600}>
          {valueGetter(shipment.shipmentNumber)}
        </Typography>
      </Box>}
      { /* Order Number */}
      {shipment.ordersNumbers && <Box sx={{
        display: "flex",
        gap: '0.5rem',
        py: "0.25rem"
      }}>
        <Typography>{orderNumb()} </Typography>
        <Typography fontWeight={600}>
          {valueGetter(shipment.ordersNumbers)}
        </Typography>
      </Box>}
      { /* COX PO NUMBER */}
      {shipment.coxPONumber && <Box sx={{
        display: "flex",
        gap: '0.5rem',
        py: "0.25rem"
      }}>
        <Typography>Costumer PO Number:</Typography>
        <Typography fontWeight={600}>
          {valueGetter(shipment.coxPONumber)}
        </Typography>
      </Box>}
      {
        /* Carrier */
      }
      <Box sx={{
        display: "flex",
        gap: '0.5rem',
        py: "0.25rem"
      }}>
        <Typography>Carrier:</Typography>
        <Typography fontWeight={600}>
          {valueGetter(getCarrierShipment(shipment))}
        </Typography>
      </Box>
      {
        /* LoadId */
      }
      {loadId && <Box sx={{
        display: "flex",
        gap: '0.5rem',
        py: "0.25rem"
      }}>
        <Typography>Load ID:</Typography>
        <Typography fontWeight={600}>
          {valueGetter(loadId)}
        </Typography>
      </Box>}
      { /* Integrations */}
      {shipment.mode === "TL" && <Box sx={{
        display: "flex",
        gap: '0.5rem',
        py: "0.25rem"
      }}>
        <Typography>QE ID: </Typography>
        <Typography fontWeight={600}>
          {valueGetter(
            shipment.integrations
              .find((e) => e.metadata && e.metadata.qeId)
              ?.metadata?.qeId || ""
          )}
        </Typography>
      </Box>}
      <Box sx={{
        display: "flex",
        gap: '0.5rem',
        py: "0.25rem"
      }}>
        { !tenant.modules?.spotmarket && (
          <>
            <Typography>PRO Number: </Typography>
            <Typography fontWeight={600}>
              {valueGetter(ShipmentUtils.getProNumber(shipment))}
            </Typography>
          </>
        )}
      </Box>
      {shipment.mileage && <Box sx={{
        display: "flex",
        py: "0.25rem",
        gap: '0.5rem',
      }}>
        <Typography>Mileage:</Typography>
        <Typography fontWeight={600}>
          {valueGetter(shipment.mileage.distanceInMiles)}mi, about {valueGetter(shipment.mileage.hours)}h {valueGetter(shipment.mileage.minutes)}min
        </Typography>
      </Box>}
      {shipment.price && isBackOfficeUser && <Box sx={{
        display: "flex",
        py: "0.25rem",
        gap: '0.5rem',
      }}>
        <Typography>Price:</Typography>
        <Typography fontWeight={600}>
          {valueGetter(shipment.price)}
        </Typography>
      </Box>}
      {shipment?.metadata?.stuck  && <Box sx={{
        display: "flex",
        py: "0.25rem",
        gap: '0.5rem',                
      }}>        
        <Typography>Hours Elapsed:</Typography>
        <Typography fontWeight={600}>
          {valueGetter(Math.trunc(shipment?.metadata?.stuck?.hoursElapsed))}
        </Typography>                
      </Box>
      }
      {shipment?.metadata?.stuck?.lastNote  && <Box sx={{
        display: "flex",
        py: "0.25rem",
        gap: '0.5rem',                
      }}>        
        <Typography>Last Note:</Typography>
        <Typography fontWeight={600}>
          {valueGetter(Math.trunc(shipment?.metadata?.stuck?.lastNote))}
        </Typography>                
      </Box>
      }
      { tenant.modules?.spotmarket &&
      <Box sx={{
        display: "flex",
        py: "0.25rem",
        gap: '0.5rem',
      }}>
        <Typography>Rate:</Typography>
        <Typography fontWeight={600}>
          { currencyGetter(shipment?.metadata?.spotmarket?.postedRate) }
        </Typography>
      </Box> }
      { tenant.modules?.spotmarket &&
      <Box sx={{
        display: "flex",
        py: "0.25rem",
        gap: '0.5rem',
      }}>
        <Typography>Truck Rate Range:</Typography>
        <Typography fontWeight={600}>
          { currencyGetter(shipment?.metadata?.spotmarket?.truckRateRange?.min) } 
          → 
          { currencyGetter(shipment?.metadata?.spotmarket?.truckRateRange?.max) }
        </Typography>
      </Box> }

    </Box>
  );
}