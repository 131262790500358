import {
    Constants,
    LocationUtils
} from ".";
import {
    capitalize
} from "lodash";
import {
    ShipmentUtils
} from ".";
import {
    ShipmentLocation
} from "src/models/shipmentLocation";
import {
    equipments as spotmarketEquipments
} from "src/models/spotmarketEquipments";


export function getPONumberLabel(tenantId) {
    if (tenantId === Constants.SPIERS_TENANT_ID) return 'Alfred ID';
    if (tenantId === Constants.COX_TENANT_ID) return 'COX PO Number';
    else return 'PO Number';
}

export function getEquipmentFromShipment(shipment) {
    if (shipment.mode === 'LTL') return 'LTLVan';

    if (!shipment.equipment) return undefined;

    const tmp = shipment.equipment;
    delete tmp._id;

    return capitalize(Object.keys(tmp).find(value => tmp[value]));
}

export function getSpotmarketEquipments(equipments) {
    if (!equipments || equipments.length === 0) return [];
    return equipments.map((key) => {
        const foundEquipment = spotmarketEquipments.find((item) => item.key === key);
        return foundEquipment ? foundEquipment.label : key;
    });
}

export function getUploadablePackageSlips(shipment) {
    const result = [];
    
    const checkNeedsUpload = (item) => item.packageSlip && !item.packageSlip.url;;
    const addPackageSlip = (item, index) => result.push({
        itemId: item.itemId,
        index,
        file: item.packageSlip,
    });

    const digestItems = (items, clean) => items.forEach((item, index) => {
        const isPackageSlipNotUploaded = checkNeedsUpload(item);
        if (isPackageSlipNotUploaded) {
            if (!clean) addPackageSlip(item, index);
            delete item.packageSlip;
        }
    });

    if (shipment.items) digestItems(shipment.items);
    else {
        digestItems(shipment.pickups[0].items);
        digestItems(shipment.stops[0].items);
    }

    return result;
}

export function getUploadableAttachment(shipment) {
    const result = [];
    const checkNeedsUpload = (shipment) => shipment.attachment && !shipment.attachment.url;;
    const isPackageSlipNotUploaded = checkNeedsUpload(shipment);

    if (isPackageSlipNotUploaded) {
        result.push({
            file: shipment.attachment
        });
        delete shipment.attachment;
    }

    return result;
}

export function getProNumber(shipment) {
    let trackingNumber = null;
    if (shipment.mode === 'LTL') {
        trackingNumber = shipment.tracking?.ProNumber;
    } else {
        const integration = shipment.integrations?.find(integration => integration?.metadata?.qeId);
        trackingNumber = integration?.metadata?.qeId;
    }
    return trackingNumber;
}

export function combine(shipments) {
    if (!shipments || !shipments.length) return;

    const locations = shipments
        .map(shipment => toLocations(shipment))
        .flat()
        .map((location, index) => ({
            ...location,
            position: index,
        }));
    const items = toUniqueItems(locations);

    const uniqueLocations = [];
    locations.forEach(location => {
        const locationItems = [];
        items.forEach((item, index) => {
            if (location.items.some(i => i._id === item._id)) {
                locationItems.push(item._id ?? index);
            }
        });
        location.items = locationItems;

        const locationIndex = uniqueLocations.findIndex(v => LocationUtils.isEqual(v, location));
        if (locationIndex >= 0) {
            const tmp = uniqueLocations[locationIndex];
            uniqueLocations[locationIndex] = {
                ...tmp,
                items: [...tmp.items, ...location.items],
            };
        } else {
            uniqueLocations.push(location);
        }
    });

    return {
        shipments,
        shipmentIds: shipments.map(shipment => shipment._id),
        locations: uniqueLocations,
        items,
        isMultiLegs: uniqueLocations.length > 2,
    }
}

export function toLocations(shipment) {
    const items = shipment.pickups.map(pickup => pickup.items).flat(Infinity).map(item => ({
        ...item
    }));

    const locations = [
        ...shipment.pickups.map((pickup, index) => ({
            ...ShipmentLocation.fromPickup(pickup),
            position: pickup.position ?? index,
            items: shipment.combinedFrom?.length ? pickup.items : items,
            shipmentId: shipment._id,
            primaryReference: shipment.primaryReference,
            type: 'pickup',
        })),
        ...shipment.stops.map((stop, index) => ({
            ...ShipmentLocation.fromStop(stop),
            position: stop.position ?? index + shipment.pickups.length,
            items: shipment.combinedFrom?.length ? stop.items : items,
            shipmentId: shipment._id,
            primaryReference: shipment.primaryReference,
            type: 'delivery',
        }))
    ];

    return locations.sort((a, b) => a.position - b.position);;
}

export function toUniqueItems(locations) {
    return locations
        .filter(location => location.type === 'pickup')
        .map(location => location.items.map(item => ({
            ...item,
            shipmentId: location.shipmentId,
            primaryReference: location.primaryReference,
        })))
        .flat(Infinity)
        .map((item, index) => ({
            ...item,
            poNumber: item.poNumber ? Array.isArray(item.poNumber) ? item.poNumber : item.poNumber?.split(',') ?? [] : [],
            sequence: item.sequence ?? index + 1,
            shipmentId: item.shipmentId,
            primaryReference: item.primaryReference,
        }));
}